import React from 'react'

const ModifyPage = ({ changePage }) => {
  return (
    <div id='modifyPage' className='frame'>
        글 수정
    </div>
  )
}

export default ModifyPage